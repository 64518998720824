
import Vue from 'vue';
import store from '@/store';

export const toRem = (n: number, u: number = 108): string | number => {
  return n ? (typeof n === 'string' ? n : `${(n / u).toFixed(4)}rem`) : '0';
}
export const findIP = (callback: any): void => {
  /* tslint:disable */
  const mwindow: any = window;
  const myPeerConnection: any = mwindow.RTCPeerConnection || mwindow.mozRTCPeerConnection || mwindow.webkitRTCPeerConnection; // compatibility for firefox and chrome
  const pc: any = new myPeerConnection({ iceServers: [] });
  const noop: any = (): void => { };
  const localIPs: any = {};
  const ipRegex: any = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

  function ipIterate(ip: string) {
    if (!localIPs[ip]) {
      callback(ip);
    }
    localIPs[ip] = true;
  }
  pc.createDataChannel("");
  pc.createOffer().then((sdp: any) => {
    sdp.sdp.split('\n').forEach((line: any) => {
      if (line.indexOf('candidate') < 0) {
        return;
      }
      line.match(ipRegex).forEach(ipIterate);
    });
    pc.setLocalDescription(sdp, noop, noop);
  });
  pc.onicecandidate = (ice: any) => {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) {
      return;
    }
    ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
  };
  /* tslint:enable */
};
export const appSize = (size: number): string => {
  let arr: any[] = [];
  let temp: any[] = [];
  let i: number = 0;
  let num: any = '';
  let unit: string = '';
  if (size === 0) {
    num = 0
    unit = 'KB'
  } else if (size < 1000) {  // < 1000B
    num = size
    unit = 'B'
  } else if (size < 1024000) {  // < 1000KB
    num = Math.round(size / 1024)
    unit = 'KB'
  } else if (size < 1024 * 102400) {  // < 100MB
    num = Number(size / 1024 / 1024).toFixed(1)
    unit = 'MB'
  } else if (size < 1024 * 1024 * 999.49) {  // < 999.49MB
    num = Math.round(size / 1024 / 1024)
    unit = 'MB'
  } else {
    num = Number(size / 1024 / 1024 / 1024).toFixed(2)
    unit = 'GB'
  }
  num += ''
  arr = num.split('.');
  temp = arr.length > 1 ? arr[1].split('') : [];
  for (i = temp.length - 1; i >= 0; i--) {
    if (temp[i] !== '0') {
      num = arr[0] + '.' + temp.slice(0, i + 1).join('')
      break
    }
  }
  if (i === -1) {
    num = arr[0]
  }
  return num + unit
}
export const appDownloadCount = (count: number, text?: string): string => {
  let downloadCount: string = '';
  const suf = text ? text : '人安装';
  // 安装次数
  if (count >= 10000000) {
    downloadCount = (count / 100000000).toFixed(2) + '亿' + suf;
  } else if (count >= 10000000) {
    downloadCount = (count / 1000000).toFixed(0) + '千万' + suf;
  } else if (count >= 1000000) {
    downloadCount = (count / 1000000).toFixed(0) + '百万' + suf;
  } else if (count >= 10000) {
    downloadCount = (count / 10000).toFixed(0) + '万' + suf;
  } else {
    downloadCount = !!count ? (count + suf) : (0 + suf);
  };
  return downloadCount;
}
export const formatNumber = (n: number): string => {
  const str = n.toString();
  return str[1] ? str : `0${str}`;
}
export const formatTime = (time: any, fat?: string): string => {
  let format: string = 'Y/M/D H:N:S';
  if (fat) {
    format = fat;
  }
  const times: any = {
    Y: time.getFullYear(),
    M: time.getMonth() + 1,
    D: time.getDate(),
    H: time.getHours(),
    N: time.getMinutes(),
    S: time.getSeconds(),
  };
  const map = ['Y', 'M', 'D', 'H', 'N', 'S'];
  const position: any[] = [];
  for (const it of map) {
    const reg: any = new RegExp(it + "\\W*");
    const idx: number = format.search(reg);
    if (idx > -1) {
      position.push({ p: idx, k: it });
    }
  }
  // .sort((a, b) => a.k - b.k);
  const strs: any = position.reduce((aa: any[], it: any, idx: number): any[] => {
    const prev = position[idx - 1] || { p: 0 };
    const v: string = format.substr(idx === 0 ? 0 : (prev.p + 1), it.p - prev.p - 1);
    aa.push(v || '');
    aa.push(times[it.k]);
    // last add string
    if (position.length === (idx + 1)) {
      aa.push(format.substr(it.p + 1));
    }
    // 把区间取出来
    return aa;
  }, []);
  return strs.join('');
}
export const getUrlParam = (key: string, url?: string): any => {
  const reg: any = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
  let search: any = url ? ('?' + url.split('?')[1]) : (typeof window === 'undefined' ? '' : window.location.search);
  search = decodeURIComponent(search);
  const r = search.substr(1).match(reg);
  if (r !== null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}
export const setStorage = (id: string, val: string | boolean): void => {
  const key = 'game_external';
  let storage: any = localStorage[key];
  if (!storage) {
    const obj: any = {};
    obj[id] = val;
    localStorage[key] = JSON.stringify(obj);
  } else {
    storage = JSON.parse(storage);
    storage[id] = val;
    localStorage[key] = JSON.stringify(storage);
  }
}
export const getStorage = (id: string): any => {
  const key = 'game_external';
  let storage: any = localStorage[key];
  if (storage) {
    storage = JSON.parse(storage);
    for (const k in storage) {
      if (k === id) {
        return storage[k];
      }
    }
  }
  return false;
}
export const createComponentProxy = (S: any, data: any) => {
  const VueTmp: any = Vue
  // 为了可以让修改data时界面得到响应
  /* let vm = new Vue({
      data
  });
  vm.$destroy(); */
  S._Ctor = null;
  const Stub = Vue.extend(S);
  const Proxy = function (options: any) {
    const propsData = options.propsData = VueTmp.util.extend(options.propsData || {}, data);
    const vm = new Stub(options);
    /* const ufp = vm._updateFromParent;
    vm._updateFromParent = function () {
        arguments[0] = VueTmp.util.extend(arguments[0] || {}, propsData);
        if (ufp) {
            ufp.apply(this, arguments);
        }
    } */
    return vm;
  }
  VueTmp.util.extend(Proxy, Stub);
  return Proxy;
}

/**
 * get the first scroll parent of an element
 * @param  {DOM} elm    the element which find scorll parent
 * @return {DOM}        the first scroll parent
 */
export const getScrollParent = (elm: any): any => {
  let val: any = ''
  let tag: any = elm
  do {
    const overflowY: any = window.getComputedStyle(tag).overflowY;
    if (tag.tagName === "BODY") {
      val = window
    } else if (["scroll", "auto"].indexOf(overflowY) > -1) {
      val = tag;
    }
    tag = tag.parentNode
  } while (tag && !val);
  return val
}

/**
 * get current distance from footer
 * @param  {DOM} elm    scroll element
 * @param  {String} dir   calculate direction
 * @return {Number}     distance
 */
export const getCurrentDistance = (elm: any, warp: any, dir?: string): number => {
  let distance: any;
  const scrollTop: number = isNaN(elm.scrollTop)
    ? elm.pageYOffset
    : elm.scrollTop;
  // 窗口高度
  const scrollHeight: number =
    elm === window ? document.body.scrollHeight : elm.scrollHeight;
  const warpRect: any = warp.getBoundingClientRect();
  const warpOffset: any = offset(warp);
  const gap = scrollHeight - warpOffset.top - warpRect.height;
  if (dir === "top") {
    distance = scrollTop;
  } else {
    // 文档高度
    const innerHeight: number = elm === window ? window.innerHeight : elm.offsetHeight;
    distance = scrollHeight - innerHeight - scrollTop - gap;
    // gap 代表当前容器离底部的距离
  }
  return distance;
}
export const offset = (el: any): any => {
  let dom: any = el;
  let iTop = 0;
  let iLeft = 0;
  do {
    iTop += dom.offsetTop;
    iLeft += dom.offsetLeft;
    dom = dom.offsetParent;    // 如果DOM 节点 的parentNode存在，把当前的节点赋予成parentNode；
  } while (dom.offsetParent);
  return { top: iTop, left: iLeft }
}

export const getBlob = (url: string): any => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    // 避免 200 from disk cache
    url = url + `?r=${Math.random()}`
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        reject(xhr.status)
      }
    }
    xhr.onerror = (e) => {
      console.log('request error');
      reject(e)
    }
    xhr.onprogress = (e) => {
      console.log('onprogress', e)
    }
    xhr.send()
  })
}
export const saveAs = (blob: any, filename: string): void => {
  const navigator = window.navigator as any; // Use 'any' to bypass TypeScript type checking
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    const anchor: any = document.createElement('a')
    const body: any = document.querySelector('body')
    anchor.href = window.URL.createObjectURL(blob)
    anchor.download = filename

    anchor.style.display = 'none'
    body.appendChild(anchor)

    anchor.click()
    body.removeChild(anchor)

    window.URL.revokeObjectURL(anchor.href)
  }
}
export const formatSeconds = (value: number) => {
  let theTime = Number(value);    // 需要转换的时间秒
  let theTime1 = 0;   // 分
  let theTime2 = 0;   // 小时
  let theTime3 = 0;   // 天
  if (theTime > 60) {
    theTime1 = Math.round(theTime / 60);
    theTime = Math.round(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = Math.round(theTime1 / 60);
      theTime1 = Math.round(theTime1 % 60);
      if (theTime2 > 24) {
        // 大于24小时
        theTime3 = Math.round(theTime2 / 24);
        theTime2 = Math.round(theTime2 % 24);
      }
    }
  }
  let result = '';
  if (theTime > 0) {
    result = "" + Math.round(theTime) + "秒";
  }
  if (theTime1 > 0) {
    result = "" + Math.round(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + Math.round(theTime2) + "小时" + result;
  }
  if (theTime3 > 0) {
    result = "" + Math.round(theTime3) + "天" + result;
  }
  return result;
}
export const rndNum = (randomFlag: boolean, min: number, max?: number) => {
  /*
  ** randomWord 产生任意长度随机字母数字组合
  ** randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
  **
  */
  let str = "";
  let range = min;
  const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

  // 随机产生
  if (randomFlag) {
    if (max) {
      range = Math.round(Math.random() * (max - min)) + min;
    }
  }
  for (let i = 0; i < range; i++) {
    const pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
}
export const deepClone = function (source: any) {
  const targetObj: any = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for (const keys in source) { // 遍历目标
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === 'object') { // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = deepClone(source[keys]);
      } else { // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
}

const Utils = {
  toRem,
  findIP,
  appSize,
  appDownloadCount,
  formatNumber,
  formatTime,
  getUrlParam,
  setStorage,
  getStorage,
  createComponentProxy,
  getBlob,
  saveAs,

  getScrollParent,
  getCurrentDistance,
  offset,
  formatSeconds,
  rndNum,
  deepClone
}
export default Utils