import Vue from 'vue';
import Builder from '@/components/base/builder';
import Toast from '@/components/base/toast/toast.vue';

// const Loading = Vue.extend(loading);
// let instance: any;
let singleInstance: any = null;
let timer: any = null;
interface ItoastOps {
    ctx?: any;
    msg?: string;
    duration?: number;
    component?: any;
    anchor?: any;
    replace?: boolean;
}

class ToastBuilder extends Builder {
    public static hide(): void {
        if (singleInstance) {
            singleInstance.$destroy();
            singleInstance._bindingCtx = null;
            singleInstance = null;
        }
    }
    public static show(options: any = {}): void {
        if (typeof window === 'undefined') {
            return;
        }
        let ops: ItoastOps = { replace: true};
        if (typeof options === 'string') {
            ops = { ...ops, msg: options}
        } else if (options instanceof Object) {
            if (typeof options === 'function') {
                ops = { ...ops, component: Vue.extend(options) }
            } else {
                ops = {...ops, ...options}
            }
        } else {
            console.log('参数错误')
            return;
        }
        // 是否需要重复生成
        if (!singleInstance || ops.replace) {
            const instance = new ToastBuilder(ops.ctx || this)
            singleInstance = instance.createComp(ops);
            singleInstance._bindingCtx = instance.ctx;
            // 回收对象
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (ops.duration !== 0) { singleInstance = null; }
            }, ops.duration || 1000);
        }
        return singleInstance;
    }
    constructor(ctx: any) {
        super(ctx, Toast)
    }
}
export default ToastBuilder