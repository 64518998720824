import { Vue, Component } from 'vue-property-decorator';
import Utils from '@/utils';
import '@/common/tongji';
import { routerMap } from '@/configs';
import { isServer } from '@/configs/index';
import ExposureDirective from "@/directives/exposure.ts";

export default {
    data: () => {
        return {
            dataMore: true,
            loadStatus1: '',
            pageMax: 20,
            currIndex: 0,
            isServer
        }
    },
    directives: {
        exposure: ExposureDirective
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        const self: any = this;
        // 当前是详情页
        if (from.name.indexOf('Detail') > -1) {
            // 进入的是列表页面
            if (to.name.indexOf('List') > -1) {
                to.meta.keepAlive = true
            }
        }
        // 当前是列表页面
        if (from.name.indexOf('List') > -1) {
            // 进入的是详情页面
            if (to.name.indexOf('Detail') > -1) {
                from.meta.keepAlive = true
            } else {
                from.meta.keepAlive = false
                self.$destroy()
            }
        }
        next()
    },
    methods: {
        loadMoreHandler1() {
            const self: any = this;
            if (!self.dataMore) {
                self.loadStatus1 = 'complete' + self.currIndex
                return;
            }
            // 加载更多
            self.currIndex += self.pageMax;
            self.getData('more')
        },
        // type是事件类型
        clickBlock(data: any, type?: string, item?: any): void {
            const self: any = this;
            const datas = Object.assign({}, data, item ? item : {});
            let link = '';
            let key = 'click'
            // 只有更多才会有type
            if (typeof type === 'string') {
                if (type === 'more') {
                    link = datas.moreLink;
                } else {
                    link = type;
                }
                key = 'click_more'
            } else {
                link = datas.blockLink
            }
            datas.toLink = link
            const options = { ...self.assembleParams(datas, key), ...self.getPrevParams() };
            if (options.block_type === 'banner' || options.block_type === 'channel_ad_f6') {
                // 区分点击事件
                self.sendAction('click' + (options.block_type === 'banner' ? '_banner' : ''), options);
                self.toBanner(options)
            } else {
                self.sendAction(key, options);
                self.toPage(link || 'gameDetail', options); // 跳转到资讯列表页
            }
        },
        assembleParams(data: any, type?: string): any {
            const Type = type ? type : 'exposure';
            let options: any = {
                request_id: (new Date()).getTime(), // 请求ID，唯一标识每一次请求
                data_source: data.data_source,  // 数据的来源
                _page_: data.data_source,  // 数据的来源
                content_type: data.ctype || data.btype,
                content_name: data.name || data.title,	// 内容名称
                content_id: data.content_id || data.id,   // 内容id
                block_id: data.block_id || 0, // 榜单块的id
                block_name: data.block_name, // 榜单块的名字，实际上就是标题
                block_type: data.block_type, // 模块类型，
                rank_id: data.rank_id, // 所在榜单ID
                rank_pos: data.px || 0, // 在整个显示的榜单列表的位置 x
                pos_hor: data.px || 0, // x
                pos_ver: data.py, // y,这里的垂直是相对于整个页面不是相对于block
            };
            const options1: any = {};
            // 如果事件类型是点击更多，那就不需要内容类型了
            if (Type === 'click_more') {
                options.content_type = data.moreLink
            }
            // 点击事件类型
            if (Type.indexOf('click') > -1) {
                options1.forward_page_type = data.toLink || data.type   // 将要跳转的内容类型,只有资讯和游戏
            };
            // 只有轮播图和刚刚才有的
            if (options.block_type === 'banner' || options.block_type === 'channel_ad_f6') {
                options.content_type = 'ad' // data.type
                options1.ad_type = data.type
                options1.content_id = data.aid  // 特殊的情况
                options1.ad_content_id = data.content_id
                if (data.type === 'app') {
                    options1.app_id = data.content_id // 游戏id
                    options1.pkg = data.package_name || data.pkg // 游戏包名
                }
            }
            // 不同的内容类型上报不同的字段
            if (options.content_type === 'game') {
                options1.app_id = data.id // 游戏id
                options1.pkg = data.package_name || data.pkg // 游戏包名
                    // game_type: 0, //普通：0, 测试：1 只有游戏详情才有此字段
            }
            options = Object.assign({}, options, options1);
            return options;
        },
        getPrevParams (data?: any, prefix?: string): any {
            const self: any = this;
            const adata: any = data ? data : null;
            const p: any = typeof prefix !== 'undefined' ? prefix : 'source_';
            return {
                [`source_page`]: adata ? adata.data_source : (Utils.getUrlParam('source_page') || ''),
                [`${p}pos_ver`]: adata ? adata.pos_ver : Utils.getUrlParam('source_pos_ver'), // y
                [`${p}pos_hor`]: adata ? adata.pos_hor : Utils.getUrlParam('source_pos_hor'),   // x
                [`${p}block_name`]: adata ? adata.block_name : (Utils.getUrlParam('source_block_name') || ''),
                [`${p}block_id`]: adata ? adata.block_id : Utils.getUrlParam('source_block_id'),
                [`${p}block_type`]: adata ? adata.block_type : (Utils.getUrlParam('source_block_type') || ''),
                [`${p}rank_id`]: adata ? adata.rank_id : Utils.getUrlParam('source_rank_id'), // 所在榜单ID
                [`${p}rank_pos`]: adata ? adata.rank_pos : Utils.getUrlParam('source_rank_pos'), // 在榜单中的位置 x
            }
        },
        sendAction (Action: string, params: any): void {
            const self: any = this;
            if (typeof window !== 'undefined') {
                let MeizuBH = (window as any).MeizuBH
                const tongjiInfo: any = {
                    action: Action,
                    action_time: Utils.formatTime(new Date()),
                    ...self.getCommonParams(),
                };
                const options = Object.assign({}, tongjiInfo, params || {});
                MeizuBH && MeizuBH(options);
            }
        },
        getCommonParams () {
            const self: any = this;
            return {
                ip: self.$store.state.ip,
                unique_id: self.$store.state.unique_id,
                cid: self.$store.state.cid
            }
        },
        exposureData (data: any, noWatch?: boolean): any {
            // console.log(1, data)
            const self: any = this
            return { ...self.assembleParams(data, 'exposure'), ...self.getCommonParams(), ...self.getPrevParams(), noWatch }
        },
        toPage(name: string, params: any): void {
            const self: any = this;
            const paths = routerMap[name || 'index'].path.split('/:')
            const contentId = params.app_id || params.content_id || params.ad_content_id;
            self.$router.push({
                path: paths[0] + (paths[1] ? ('/' + contentId) : ''),
                query: {
                    contentId,
                    ...self.getPrevParams(params),  // 把父级参数传递到子级页面
                }
            });
        },
        toBanner(params: any) {
            const self: any = this;
            // 判断是进游戏详情
            if (params.ad_type === "app") {
                self.toPage('gameDetail', params);
            } else if (params.ad_type === 'activity') {
                // 活动
                self.$store
                    .dispatch("fetchActivityDetail", {
                        context: this,
                        type: params.type,
                        id: params.ad_content_id,
                        params: {}
                    })
                    .then((res: any) => {
                        if (res.value) {
                            window.location.href = res.value.html_url;
                        } else {
                            self.$toast.show(res.message);
                        }
                    })
                    .catch((err: any) => {
                        console.log(err);
                        self.$toast.show(err.message);
                    });
            } else {
                self.$toast.show('活动类型不支持！');
            }
        }
    }
}