



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "Totast"
})
export default class Totast extends Vue {
  @Prop({ default: "msg" }) private msg!: string;
  @Prop({ default: 1000 }) private duration!: number;
  @Prop({ default: null }) private component!: any;
  @Prop({ default: null }) private anchor!: any;
  private open: boolean = true;
  private componentName: string = "";
  private gap: number = 20;

  get boxStyle(): string {
    if (this.anchor) {
      const anchor = this.anchor.target;
      const boxRect = anchor.getBoundingClientRect();
      return `top: ${boxRect.y - this.gap}px; left: ${boxRect.x + boxRect.width / 2}px;transform: translate(-50%, -100%);-webkit-transform: translate(-50%, -100%);background: transparent;padding: 0;`;
    }
    return "";
  }
  private beforeMount(): void {
    const self: any = this;
    // 判断是否是组件
    if (self.component) {
      self.$options.components.Other = self.component;
      self.componentName = "Other";
    }
  }
  private mounted(): void {
    const self: any = this;
    // 0的时候不自动隐藏
    if (self.duration !== 0) {
      setTimeout(function() {
        self.open = false;
      }, self.duration);
    }
  }
  private destroyed(): void {
    this.$el.remove();
  }
  private afterLeave(): void {
    this.$destroy();
  }
}
