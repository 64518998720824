










/* tslint:disable:no-console */
import { Component, Vue, Watch } from 'vue-property-decorator';
import Top from '@/components/Top.vue';
import Footer from '@/components/Footer.vue';
import mixins from '@/mixin/index.ts';
import utils from '@/utils';
import md5 from 'md5'
import Fingerprintjs from 'fingerprintjs'

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'App',
  components: { Top, Footer },
  mixins: [mixins],
})
export default class App extends Vue {
  private created(): void {
    const self: any = this;
    // 渠道id
    self.$store.commit('setDefaultData', {cid: self.$route.query.cid});
  }
  private beforeMount(): void {
    const self: any = this;
    // 生产浏览器唯一unique_id
    const fingerprint = new Fingerprintjs({ canvas: true, screen_resolution: true }).get();
    self.$store.commit('setUniqueId', fingerprint)
    // 获取ip
    self.$store.dispatch('fetchIp', {context: this});
    // 设置进入页面的的缓存
    self.pageAction();
  }
  private pageAction(route: any, from?: string | undefined): void {
    const self: any = this;
    const routerName: string | undefined = self.$route.name;
    const routerNameKey: string | undefined = md5(self.$route.fullPath);
    const enter: any = utils.getStorage(`enter_${routerNameKey}`);
    let isEnter: boolean = false;
    // 已经进入过当前页面
    if (enter) {
      isEnter = true;
    } else {
      utils.setStorage(`enter_${routerNameKey}`, true);
    }
    // 进入页面
    self.sendAction(`enter_page`, { isEnter, from, page: routerName, ...self.getPrevParams()});
  }
  @Watch('$route')
  private onChangeRoute(val: any, old: any) {
    // 上报不同页面的进入事件
    this.pageAction(val, old.name);
  }
}
