interface IblockMapItem {
  type: string
  layout: string
  blockLink?: string
  moreLink?: string
}
interface IblockMap {
  [name: string]: IblockMapItem[]
}
export const isServer = process.env.VUE_ENV === "server";
// 路由配置
export const routerMap: any = {
  index: {
    path: '/',
    keepAlive: true,
  },
  searchDetail: {
    path: '/searchDetail',
    keepAlive: false
  },
  liveAppInstall: {
    path: '/liveAppInstall',
    keepAlive: false
  },
  hotTagList: {
    path: '/hotTagList',
    keepAlive: true
  },
  wealList: {
    path: '/weal/list/:id',
    keepAlive: true,
  },
  wealDetail: {
    path: '/weal/detail/:id',
    keepAlive: false,
  },
  newsList: {
    path: '/news/list/:id',
    keepAlive: true,
  },
  newsDetail: {
    path: '/news/detail/:id',
    keepAlive: false,
  },
  gameList: {
    path: '/game/list/:id',
    keepAlive: true,
  },
  gameDetail: {
    path: '/game/detail/:id',
    keepAlive: false,
  },
  commentaAppList: {
    path: '/comment/appList/:id',
    keepAlive: true,
  },
  commentList: {
    path: '/comment/list/:id',
    keepAlive: true,
  },
  commentDetail: {
    path: '/comment/detail/:id',
    keepAlive: false,
  },
  about: {
    path: '/about',
    keepAlive: false,
  }
}
// 模块的配置信息
export const blockMap: IblockMap = {
  searchDetail: [{ type: 'search', layout: '1-1', blockLink: 'searchDetail' }], // 搜索详情,
  hotTagList: [{ type: 'gameList', layout: '1-1', blockLink: 'gameList', moreLink: "gameDetail" }], // 热门标签
  liveAppInstall: [{ type: 'banner', layout: '1-1', blockLink: 'gameDetail' }, { type: 'gift_rown_col4_f6', layout: 'n-4', blockLink: 'wealDetail', moreLink: 'wealList' }, { type: 'rank_r3_c1_expand', layout: 'n-1', blockLink: 'gameDetail', moreLink: 'gameList' }, { type: 'gift_rown_col4_f6', layout: 'n-4', blockLink: 'wealDetail', moreLink: 'wealList' }, { type: 'rank_r3_c1_expand', layout: 'n-1', blockLink: 'gameDetail', moreLink: 'gameList' }], // 直播应用下载
  Swiper: [{ type: 'banner', layout: '1-1', blockLink: 'gameDetail' }], // 轮播图,
  AppBlockNormal: [{ type: 'rank_r3_c1_expand', layout: 'n-1', blockLink: 'gameDetail', moreLink: 'gameList' }, { type: 'row3_col1', layout: 'n-1', moreLink: '' }], // 今日推荐,
  ImgBlockNormal: [{ type: 'channel_ad_f6', layout: '1-1', blockLink: 'gameDetail' }, { type: 'info_r1_cn_expand', layout: '1-n', blockLink: 'newsDetail', moreLink: 'newsList' }], // 最佳游戏, 今日头条?
  AppBlockSmall: [{ type: 'row1_coln_f6', layout: '1-n', blockLink: 'gameDetail', moreLink: 'gameList' }, { type: 'gift_rown_col4_f6', layout: 'n-4', blockLink: 'wealDetail', moreLink: 'wealList' }],  // 生存竞技, 热门游戏;福利礼包
  AppBlockCard: [{ type: 'info_rn_c1_big_img_expand', layout: 'n-1', blockLink: 'newsDetail', moreLink: 'newsList' }],  // 精华攻略
  AppBlockCoupon: [{ type: 'coupon_row1_col1_f6', layout: 'n-1', blockLink: 'gameDetail' }],   // 超值优惠券
  AppBlockCardSmall: [{ type: 'evaluate_row1_coln_f7', layout: '1-n', blockLink: 'gameDetail', moreLink: 'commentaAppList' }],  // 评论安利
  AppBlockKey: [{ type: 'alone_game_row1_coln_f6', layout: '1-n', blockLink: 'gameDetail', moreLink: 'gameList' }],  // 独立游戏
  NewsList: [{ type: 'info_rank', layout: 'n-1', blockLink: 'newsDetail', moreLink: 'newsList' }],   // 资讯
}
// 外部活动页的地址前缀
export const activityPrefix: string = '';
// 下载app地址
export const downloadAppUrl = (ops: any): string => {
  return `/games/public/download?game_id=${ops.appId}`;
}
export const lazyImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEXr6+uInxNMAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==';

export const pageInfo: any = {
  Title: '魅玩游戏中心_手机游戏下载官方平台',
  Keyword: '手机,手游,返利,折扣,游戏,首充减免,充值减免,充值返利,充值折扣,独立手游,火柴人手游,手游折扣,首充福利,充值福利,模拟手游优惠,折扣,抽奖,首发,测试,开服,,魅玩游戏中心,小游戏,火柴人,返利,免费,首充,减免,充值,精品,儿童,男生,女生,男性,女性,教育,赛事,联赛,官网,资讯,攻略,直播,论坛,社区,手游折扣中心,首发手游充值返利,最新手游充值折扣',
  Description: '魅玩游戏中心是国内优秀游戏平台之一,致力于为玩家提供最新、最全、最精选的手机游戏免费下载,以及个性化专题、丰富多元专属礼品等更贴心更完善的游戏服务。'
};