



































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "Loading"
})
export default class Loading extends Vue {
  @Prop({ default: "正在加载.." }) private text!: string;
  @Prop({ default: "snake" }) private spinnerType!: string;
  private visible: boolean = true;

  private convertedSpinnerType(): number {
    switch (this.spinnerType) {
      case "double-bounce":
        return 1;
      case "triple-bounce":
        return 2;
      case "fading-circle":
        return 3;
      default:
        return 0;
    }
  }
  private destroyed() {
    this.$el.remove();
  }
  private afterLeave(): void {
    this.$destroy();
  }
}
