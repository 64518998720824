import { DirectiveOptions } from 'vue'
import Exposure from '@/common/Exposure.ts'
import Utils from '@/utils';
import '@/common/tongji';

const exp: any = new Exposure((data: any) => {
    if (typeof window !== 'undefined') {
        const MeizuBH = (window as any).MeizuBH;
        const tongjiInfo: any = {
            action: 'exposure',
            action_time: Utils.formatTime(new Date()),
            ...data,
        };
        MeizuBH&&MeizuBH(tongjiInfo);
    }
})
const directive: DirectiveOptions = {
    bind(el, binding, vnode) {
        const data = binding.value
        if (typeof data.noWatch === 'undefined' || data.noWatch === false) {
            exp.add({ el, data })
        }
    },
    inserted(el, node) {
        // console.log(el)
    }
};

export default directive;