





























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DialogBase"
})
export default class DialogBase extends Vue {
  @Prop({ default: '标题' }) private title!: any;
  @Prop({ default: '我是内容' }) private content!: any;
  @Prop({ default: '取消' }) private cancelBtnTxt!: string;
  @Prop({ default: '确认' }) private confirmBtnTxt!: string;
  @Prop({ default: null }) private onCancel!: any;
  @Prop({ default: null }) private onConfirm!: any;

  private componentTitleName: string = "";
  private componentContentName: string = "";

  get boxStyle(): string {
    return ''
  }
  private beforeMount(): void {
    const self: any = this;
    // 判断标题和
    if (typeof self.title !== 'string') {
        self.$options.components.Title = self.title;
        self.componentName = "Title";
    }
    if (typeof self.content !== 'string') {
        self.$options.components.Content = self.content;
        self.componentName = "Content";
    }
  }
  private mounted(): void {
    const self: any = this;
  }
  private destroyed(): void {
    this.$el.remove();
  }
  private afterLeave(): void {
    this.$destroy();
  }
  private cancel () {
      this.$emit('onCancel', this)
  }
  private confirm () {
      this.$emit('onConfirm', this)
  }
}
