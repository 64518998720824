import request, { createRequest } from './request'
const request2: any = new createRequest(false)
const cache: any = {
  params: {
    cache: true
  }
}
// 获取首页数据
export const getHomePageData = (params: any) => request.post(`/gamesexpand/public/index/layout`, params)
export const getLiveAppData = (params: any) => request.get(`/games/public/guild/live/app_page_data`, params)
// 获取客户端下载地址
export const getClientDownloadUrl = (params: any) => request.get('/gamesclient/download', params)
// 获取app下载地址
export const getDownloadUrl = (params: any) => request.get('/games/public/download/expand/url', params)
// 获取活动详情
export const getActivityDetail = (type: string = 'activity', id: string | number, params: any) => request.get(`/games/public/${type}/detail/${id}`, params)

// 获取app列表rankId,start=0&max=50
export const getAppList = (rankId: string | number, params: any) => request.get(`/games/public/collection/detail/${rankId}`, params)
// 获取app详情
export const getAppDetail = (appId: string | number, params: any) => request.get(`/games/public/detail/${appId}`, params)
// 获取app礼包
export const getAppGift = (appId: string | number, params: any) => request.get(`/games/public/gift/${appId}/collect`, params)
// 获取app优惠券，app_id
export const getAppCoupon = (params: any) => request.get(`/games/public/coupon/task/list`, params)
// 获取app评论，start=0&max=10&game_id=1。list是老接口，score热度，time时间
export const getAppComment = (type: string, params: any) => request.get(`/games/public/evaluate/${type}`, params)
// 获取app资讯，start=0&max=50&appId=1&status=50
export const getAppNews = (params: any) => request.get(`/games/information/relate/infos`, params)

// 获取资讯列表，blockId=11206&blockType=info_rank&start=0&max=50
export const getNewsList = (rankId: string | number, params: any) => request.get(`/games/public/information/detail/${rankId}`, params)
// 获取mlink应用平台资讯内容，newsId
export const getNewsDetail = (newsId: string | number, params: any) => request.get(`/games/information/content/${newsId}`, params)
// 获取mlink应用平台资讯内容，newsId
export const getNewsDetailOther = (suffix: string | number, params: any) => request2.get(`//reader-res.mzres.com/reader/${suffix}`, { params })

// 获取福利礼包列表,type=gift_rown_col4_f6&id=10838&start=0&max=20
export const getWealList = (params: any) => request.get(`/games/public/welfare/block/list`, params)
// 获取福利礼包内容，
export const getWealDetail = (appId: string | number, params: any) => request.get(`/games/public/welfare/detail/${appId}`, params)

// 获取不同app的评论列表,start=0&max=50
export const getCommentList = (rankId: string | number, params: any) => request.get(`/games/public/information/evaluate/detail/${rankId}`, params)
// 获取当前app的评论详情,start=0&max=10&evaluate_id=11092695
export const getCommentDetail = (params: any) => request.get(`/games/public/evaluate/reply/list`, params)

// 评论赞
export const getCommentLike = (commentId: string | number, params: any) => request.get(`/games/public/evaluate/like/${commentId}`, params)
// 评论踩
export const getCommentTread = (commentId: string | number, params: any) => request.get(`/games/oauth/evaluate/tread/${commentId}`, params)

// 搜索游戏
export const getHotKeyWord = (params: string) => request.get(`games/v2/public/search/hot`, params)

export const getCollection = (rankId: number, params: string) => request.get(`games/public/collection/detail/${rankId}`, params)

export const getFocuSearchGame = (params: string) => request.get(`/games/public/seek`, params)

export const getBlurSearchGame = (params: string) => request.get(`/games/public/seek/suggest`, params)

export const getSearchIndex = (params: string) => request.get(`/gamesexpand/public/search/index`, params)

// https://api-store.mlinkapp.com/games/public/evaluate/score?start=0&max=10&game_id=3047419&access_token=&os=27&mzos=7&screen_size=1080x2016&language=zh-CN&imei=866778030029494&sn=882QAESNGCYL2&device_model=M8820&v=7.8.7&vc=7008007&net=wifi&firmware=Flyme%207.3.0.0A&custom_icon=1&dataVcode=7008007&uid=14042574