import { ActionTree } from "vuex";
import utils from '@/utils';
import { getHomePageData, getLiveAppData, getClientDownloadUrl, getDownloadUrl, getActivityDetail, getAppList, getAppDetail, getAppGift, getAppCoupon, getAppComment, getAppNews, getCommentLike, getCommentTread, getNewsList, getNewsDetail, getNewsDetailOther, getWealList, getWealDetail, getCommentList, getCommentDetail, getHotKeyWord, getCollection, getFocuSearchGame, getSearchIndex, getBlurSearchGame } from "@/api";

// 用户信息
export interface IfetchParams {
  context: any | undefined;
  params: any | undefined;
}

// 用户信息
export interface IuserInfo {
  uuid: string | undefined;
  userIcon?: string | undefined;
  userId: string | undefined;
  userName: string | undefined;
  phone: string | undefined;
}

// state结构
export interface IState {
  unique_id: number;
  ip: string | undefined;
  cid: string | undefined,
  userInfo: IuserInfo;
  pageDatas: any;
}

export const state: IState = {
  unique_id: 0,
  ip: '',
  cid: '',
  userInfo: {
    uuid: '',
    userIcon: '',   // 用户头像
    userId: '',
    userName: "", // 用户名
    phone: "", // 手机号
  },
  pageDatas: {
    indexData: [],
    liveAppData: [],
    gameDetailData: {},
    newsDetailData: {},
    wealDetailData: {
      appBlock: {},
      giftBlock: {},
      couponBlock: {},
    },
    searchDetailData: {},
    commentDetailData: {},
  }
}

export const mutations = {
  setDefaultData(ste: IState, data: any) {
    if (data.ip) {
      ste.ip = data.ip;
    }
    if (data.cid) {
      ste.cid = data.cid;
    }
  },
  // tslint:disable-next-line:no-shadowed-variable
  setUniqueId(ste: IState, id: any) {
    ste.unique_id = id
  },
  setUserInfo(ste: IState, userInfo: any) {
    ste.userInfo = Object.assign({}, ste.userInfo, userInfo)
  },
  setIndexData(ste: IState, data: any) {
    ste.pageDatas.indexData = data
  },
  setLiveAppData(ste: IState, data: any) {
    ste.pageDatas.liveAppData = data
  },
  setGameDetailData(ste: IState, data: any) {
    ste.pageDatas.gameDetailData = data
  },
  setNewsDetailData(ste: IState, data: any) {
    ste.pageDatas.newsDetailData = data
  },
  setWealDetailData(ste: IState, data: any) {
    ste.pageDatas.wealDetailData = data
  },
  setSearchDetailData(ste: IState, data: any) {
    ste.pageDatas.searchDetailData = data
  },
  setSearchBlurData(ste: IState, data: any) {
    ste.pageDatas.searchBlurGameData = data
  },
  setSearchFocuGameData(ste: IState, data: any) {
    ste.pageDatas.searchFocuGameData = data
  },
  setCommentDetailData(ste: IState, data: any) {
    ste.pageDatas.commentDetailData = data
  },
  clearPageData(ste: IState) {
    // 清空全部的ssr数据
    ste.pageDatas = {
      indexData: [],
      liveAppData: [],
      gameDetailData: {},
      newsDetailData: {},
      wealDetailData: {
        appBlock: {},
        giftBlock: {},
        couponBlock: {},
      },
      searchDetailData: {},
      searchBlurGameData: {},
      searchFocuGameData: {},
      commentDetailData: {},
    };

  }
}
export const actions: ActionTree<any, any> = {
  /* fetchDefault({ commit }, { context, params }) {
  }, */
  fetchIp({ commit }, { context, ip }) {
    // 未传值，判断当前是否有IP，否则通过方法去获取
    if (typeof ip === 'undefined') {
      if (!context.$store.state.ip) {
        utils.findIP((val: string) => {
          commit('setDefaultData', {
            ip: val
          });
        })
      }
    } else {
      commit('setDefaultData', {
        ip
      });
    }
  },
  /* fetchUserInfo({ commit }, { context, params }: IfetchParams) {
    const fparams = params || {}
    return userBase(fparams).then(res => {
      if (res.data) {
        commit('setUserInfo', res.data);
      }
      return Promise.resolve(res)
    })
  }, */
  fetchIndexData({ commit }, { context, params }) {
    const fparams = params || {}
    return getHomePageData(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchLiveAppData({ commit }, { context, params }) {
    const fparams = params || {}
    return getLiveAppData(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchHotKeyWord({ commit }, { context, params }) {
    const fparams = params || {}
    return getHotKeyWord(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchGetCollection({ commit }, { context, rankId, params }) {
    const fparams = params || {}
    return getCollection(rankId, fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchSearchIndex({ commit }, { context, params }) {
    const fparams = params || {}
    return getSearchIndex(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchGetSearchGame({ commit }, { context, params }) {
    const fparams = params || {}
    return getBlurSearchGame(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchGetFoucSearchGame({ commit }, { context, params }) {
    const fparams = params || {}
    return getFocuSearchGame(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchClientUrl({ commit }, { context, params }) {
    const fparams = params || {}
    return getClientDownloadUrl(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchAppUrl({ commit }, { context, params }) {
    const fparams = params || {}
    return getDownloadUrl(fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchActivityDetail({ commit }, { context, type, id, params }) {
    const fparams = params || {}
    return getActivityDetail(type, id, fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },

  fetchAppList({ commit }, { context, rankId, params }) {
    const fparams = params || {}
    return getAppList(rankId, fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.reject(err)
    })
  },
  fetchAppDetail({ commit }, { context, params }) {
    const fparams = params || {}
    return getAppDetail(fparams.appId, fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      return Promise.reject(err)
    })
  },
  fetchAppGift({ commit }, { context, appId, params }) {
    const fparams = params || {}
    return getAppGift.call(context, appId, fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchAppCoupon({ commit }, { context, params }) {
    const fparams = params || {}
    return getAppCoupon.call(context, fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchAppComment({ commit }, { context, type, params }) {
    const fparams = params || {}
    return getAppComment(type, fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchAppNews({ commit }, { context, params }) {
    const fparams = params || {}
    return getAppNews(fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      return Promise.reject(err)
    })
  },
  fetchNewsList({ commit }, { context, rankId, params }) {
    const fparams = params || {}
    return getNewsList.call(context, rankId, fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.reject(err)
    })
  },
  fetchNewsDetail({ commit }, { context, newsId, params }) {
    const fparams = params || {}
    return getNewsDetail.call(context, newsId, fparams).then((res: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.resolve(res)
    })
  },
  fetchNewsDetailOther({ commit }, { context, suffix, params }) {
    const fparams = params || {}
    return getNewsDetailOther.call(context, suffix, fparams).then((res: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.resolve(res)
    })
  },
  fetchWealList({ commit }, { context, params }) {
    const fparams = params || {}
    return getWealList.call(context, fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.reject(err)
    })
  },
  fetchWealDetail({ commit }, { context, appId, params }) {
    const fparams = params || {}
    return getWealDetail.call(context, appId, fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.reject(err)
    })
  },
  fetchCommentList({ commit }, { context, rankId, params }) {
    const fparams = params || {}
    return getCommentList.call(context, rankId, fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.reject(err)
    })
  },
  fetchCommentDetail({ commit }, { context, params }) {
    const fparams = params || {}
    return getCommentDetail.call(context, fparams).then((res: any) => {
      return Promise.resolve(res)
    }).catch((err: any) => {
      context.$doMotion(context, 'LOADING_SWITCH', false)
      return Promise.reject(err)
    })
  },

  fetchCommentLike({ commit }, { context, params }) {
    const fparams = params || {}
    return getCommentLike(fparams.id, fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
  fetchCommentTread({ commit }, { context, params }) {
    const fparams = params || {}
    return getCommentTread(fparams.id, fparams).then((res: any) => {
      return Promise.resolve(res)
    })
  },
}
