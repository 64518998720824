














/* tslint:disable:no-console */
import { Vue, Component, Prop } from "vue-property-decorator";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "Top"
})
export default class Top extends Vue {
  private downloadUrl: string = "";
  private mounted(): void {
    this.getData();
  }
  private getData() {
    const self: any = this;
    this.$store
      .dispatch("fetchClientUrl", {
        context: this,
        params: {}
      })
      .then((result: any) => {
        if (result.code === 200) {
          const  downloadUrl = result.value.download_url;
          const otherCDN = result.value.download_urls;
          const downloadUrl1 = otherCDN.length && otherCDN[0].url;
          console.log('下载链接：', downloadUrl || downloadUrl1);
          self.downloadUrl = downloadUrl || downloadUrl1;
        }
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || "服务器错误，请刷新！");
      });
  }
}
