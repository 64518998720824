/* tslint:disable */
const TARGET_NODE = process.env.VUE_ENV === "server";
export const MOTIONS: any = {
    LOADING_SWITCH: {
        name: '显示隐藏loading',
        do: function loadingSwitch(show: boolean) {
            const self: any = this;
            // const $loading = self.$loading.of(this);
            if (TARGET_NODE) {
                return;
            }
            // 获取详情数据
            if (show) {
                self.$loading.open({
                    text: '正在加载..',
                    spinnerType: 'fading-circle'
                });
            } else {
                self.$loading.close();
            }
        }
    },
};

export const doMotion = function (content: any , type: string): any {
    // const self: any = this;
    const params: any = [].slice.call(arguments, 2);
    const motion = MOTIONS[type];
    return new Promise((resolve: any, reject: any) => {
        if (!motion) {
            reject(new Error(`Cannot found Motion type: ${type}`));
        }
        try {
            resolve(motion.do.apply(content, params));
        } catch (err) {
            reject(err);
        }
    });
}

const install = (Vue: any) => {
    Vue.prototype.$doMotion = doMotion;
    Vue.prototype.__MOTIONS__ = MOTIONS;
}

export default install