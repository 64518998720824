






















/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
import QrCode from '@/components/widget/qrCode.vue'
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'Footer',
})
export default class Footer extends Vue {
  @Prop() private info!: any[];
  private showIcon(e: any) {
      const self: any = this;
      self.$toast.show({component: QrCode, duration: 5000, anchor: e, replace: false})
  }
  private doLink() {
      const self: any = this;
      self.$toast.hide()
  }
}
