
import Vue from 'vue';
import { createApp } from './main';
import ProgressBar from './components/ProgressBar.vue';
const isServer = process.env.VUE_ENV === "server";

let bar: any = null
// let scrollTopTimer: any = null
if (!isServer) {
    // global progress bar
    bar = Vue.prototype.$bar = new Vue(ProgressBar).$mount();
    document.body.appendChild(bar.$el);
}

// 客户端特定引导逻辑……
const { app, router, store } = createApp();

interface IWindow { __INITIAL_STATE__: any; addEventListener: any; }
declare var window: IWindow;

// 当使用 template 时，context.state 将作为 window.__INITIAL_STATE__ 状态，自动嵌入到最终的 HTML 中
if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
}

router.onReady(() => {
    // 只有在非ssr环境才生效
    // 添加路由钩子函数，用于处理 asyncData.
    // 在初始路由 resolve 后执行，
    // 以便我们不会二次预取(double-fetch)已有的数据。
    // 使用 `router.beforeResolve()`，以便确保所有异步组件都 resolve
    if (!isServer) {
        // 注入全局渠道号
        router.beforeEach((to, from, next) => {
            // 记录位置
            if (from.meta.keepAlive) {
                const $content = document.documentElement // document.querySelector('#app');
                const scrollTop = $content ? $content.scrollTop : 0;
                // 记录对应路由下的scrollTop
                from.meta.scrollTop = from.path + '-' + scrollTop;
            }

            // 如果上一页面存在cid,并且将要跳转的地址不带cid
            if (from.query.cid && !to.query.cid) {
                const { cid } = app.$store.state
                to.query.cid = cid
                next({
                    path: to.path,
                    query: to.query
                })
            } else {
                next();
            }
        })
        router.beforeResolve((to, from, next) => {
            // 获取当前路由匹配的组件数组。
            const matched = router.getMatchedComponents(to);
            const prevMatched = router.getMatchedComponents(from);

            // 我们只关心非预渲染的组件
            // 所以我们对比它们，找出两个匹配列表的差异组件
            let diffed = false;
            // 找出from和to路由需要不同的组件
            const activated = matched.filter((c, i) => {
                return diffed || (diffed = (prevMatched[i] !== c))
            });

            if (!activated.length) {
                return next();
            }

            // 进度条开始
            bar.start();
            Promise.all(activated.map((c: any) => {
                if (c.asyncData) {
                    return c.asyncData({ store, route: to });
                }
            })).then(() => {
                // 进度条结束
                bar.finish();
                next();
            }).catch(next)
        })
        /**
         * 有keepAlive 所以不需要设置位置
         */
        /* router.afterEach((to, from) => {
            // 设置之前的位置
            if (to.meta.keepAlive) {
                const scrollTops = (to.meta.scrollTop || '').split('-');    // 0:path 1:value
                const $content = document.documentElement;
                // 首页不处理通过路由的设置位置，返回会自动记录
                if (scrollTops[1] && scrollTops[0] === to.path && $content && to.path !== '/') {
                    clearTimeout(scrollTopTimer)
                    // 可能未渲染完成，最好写在组件内部
                    scrollTopTimer = setTimeout(() => {
                        $content.scrollTop = scrollTops[1];
                    }, 500);
                }
            }
        }) */
    }
    app.$mount('#app')
});

// service worker
/* if ('https:' === location.protocol) {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/service-worker.js');
        });
    }
} */