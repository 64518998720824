(function () {
    if (typeof window == 'undefined') {
        return 
    }
    var VERSION = '1.0.10';
    var WIN = window;
    var DOC = document;
    var LOCATION = WIN.location;
    var MATH = Math;
    var NAV = navigator;
    var BODY = DOC.body;
    var FALSEVAL = !1;
    var TRUEVAL = 1;
    var MZJI = WIN.MzJavascriptInterface || {};         // 浏览器/H5内公共接口
    var MZPJI = WIN.MzPrivateJavascriptInterface || {}; // 浏览器/H5内私有接口(浏览器内，需域名授权可获取)
    /**
     * 提前读取用户配置参数
     * 主要参数：
     *  // 埋点 js 载入成功后，是否阻止立即触发一次 PV 上报，默认不阻止
     *  unload: false,
     *  // 是否关闭页面位置点击区域数据的收集和上报，默认不关闭
     *  offReportClickPos: false
     *  // re 和 ru 参数的最大长度，不设置或小于 10 则表示不限制
     *  maxUrlLen: 0
     */
    var USERCFG = WIN.__mztj || {};

    var MeizuLogger = WIN.MeizuLogger = function() {};

    var MZTJ = {
        // 页面相关信息
        i: {
            ie: /msie (\d+\.\d+)/i.test(NAV.userAgent),
            align: 1, // 页面对齐方式: 1 居中, 2 右对齐, 其它值为左对齐
        },
        event: {
            /**
             * 事件绑定方法
             * @param  {Object}   el     window 、document 等对象
             * @param  {String}   evName 事件名称
             * @param  {Function} fn     事件方法
             * @return {void}
             */
            c: function (el, evName, fn) {
                if (el.attachEvent) {
                    el.attachEvent("on" + evName, function (e) {
                        fn.call(el, e);
                    });
                } else if (el.addEventListener) {
                    el.addEventListener(evName, fn, FALSEVAL);
                }
            },
            // 阻止事件默认行为方法
            // pd: function (ev) {
            //     if (ev.preventDefault) {
            //         ev.preventDefault();
            //     } else {
            //         ev.returnValue = FALSEVAL;
            //     }
            // }
        },
        url: {
            /**
             * [getQueryObj] URL 查询字符串格式化为 Object
             * @param  {String} search 查询字符串
             * @return {Object}
             */
            qo: function(search) {
                var result = {};
                var list = search.split("&");
                var i =0 ,
                    len = list.length,
                    pair;

                for (; i < len; i++) {
                    pair = list[i].split("=");
                    result[pair[0]] = pair[1];
                }

                return result;
            },
            /**
             * [queryString] 从地址栏里获取指定参数的值
             * @param  {String} key 要获取的参数名
             * @param  {String} url 要查找的 URL，为空则取当前页面地址
             * @return {String}
             */
            qs: function(key, url) {
                var result = '', i, keyValue, part;

                url = url || LOCATION.search;
                url = url.split(/&|\?/);

                key = String(key).toLowerCase();
                for (i = 0; i < url.length; i++) {
                    keyValue = url[i];
                    part = keyValue.split('=');
                    if (part[0].toLowerCase() === key) {
                        result = part[1];
                        break;
                    }
                }
                return result;
            },
            /**
             * [urlEncodeObj] 把 Object 格式的数据转换为 URL 查询字符串
             * @param  {Object} queryObj
             * @return {String}
             */
            eo: function(queryObj) {
                var arr = [], p;
                for (p in queryObj) {
                    if (queryObj.hasOwnProperty(p)) {
                        arr.push(p + '=' + encodeURIComponent(queryObj[p]));
                    }
                }
                return arr.join('&');
            },
            /**
             * 拼接Url字符串
             * @param {String} url 基础地址，可以带？也可以不带？
             * @param {Object} queryObj Get查询参数
             * @returns {String}
             * @example
             * var url = MZTJ.url.makeUrl("http://meizu.com",{
             *     sid:"xxxxxxx",
             *     key:"yyyyyyy"
             * });
             * 得到 http://meizu.com/?sid=xxxxxxx&key=yyyyyyy
             * 或者
             * var url = MZTJ.url.makeUrl("http://meizu.com","a=1&b=2");
             * 得到 http://meizu.com/?a=1&b=2
             * 自动判断是否应该加上"?"
             */
            make: function (url, queryObj) {
                if (!~url.indexOf("?")) {
                    url += "?";
                }

                if (!/\?$/.test(url)) {
                    url += "&";
                }

                if (typeof queryObj === "string") {
                    url += queryObj;
                } else {
                    /* eslint-disable no-use-before-define */
                    url += URLS.eo(queryObj);
                    /* eslint-enable no-use-before-define */
                }

                return url;
            }
        },
        util: {
            /**
             * [getTextOverFlow] 超长文本截断
             * @param  {String} text      原始文本
             * @param  {Number} maxLength 文本最大长度，超出则截断
             * @return {String}           截断后的文本
             */
            tof: function(text, maxLength) {
                return (text.length <= maxLength) ? text : text.substring(0, maxLength);
            },
            /**
             * 获取元素节点的属性值
             * @param  {Element} element
             * @param  {String} 属性名
             */
            attr: function(element, name) {
                var attrVal;

                try {
                    if (element.getAttribute) {
                        attrVal = element.getAttribute(name);
                    }
                } catch (e) {
                    if (element.attributes) {
                        attrVal = element.attributes[name];
                    }
                }

                return attrVal;
            }
        },
        // 消息接收与派送相关
        msg: {
            /**
             * 派送消息 [sendMessage]
             * @param  {Object} data   参数相关
             * @param  {Object} source   消息来源句柄
             * @param  {String} origin   消息来源域名
             */
            s: function (data, source, origin) {
                var msg = {
                    height: BODY.scrollHeight,
                    width: BODY.scrollWidth
                };

                if (data.type === 'bhel') {
                    msg.bhel = this.bhel();
                }

                // 向消息来源派送
                source.postMessage(msg, origin);
            },
            /**
             * dom 变动监听 [initMutationObserver]
             * @param  {Function} callback 发生变动时的回调方法
             */
            mo: function (config, source, origin) {
                var MutationObserver = WIN.MutationObserver ||
                    WIN.WebKitMutationObserver ||
                    WIN.MozMutationObserver;

                if (!MutationObserver || this.initedMo) {
                    return;
                }

                this.initedMo = TRUEVAL;

                if (typeof config !== 'object') {
                    config = {};
                }

                var callback = function(records) {
                    console.log('MutationObserver callback');
                    // records.map(function(record) {
                    //     console.log('Mutation type: '+ record.type, ', target: ', record.target.nodeName);
                    // });
                    this.s({
                        type: config.type // || 'height'
                    }, source, origin);
                }

                var mo = new MutationObserver(callback);

                mo.observe(BODY, {
                    'childList': TRUEVAL,
                    'subtree': TRUEVAL
                });

                // return mo;
            },
            // 获取页面所有 data-bh 和 data-bhclick 的元素信息 [getDataBhElPos]
            bhel: function () {
                var elInfo = [];
                var attr = MZTJ.util.attr;

                DOC.querySelectorAll('[data-bhclick],[data-bh]')
                // DOC.querySelectorAll('a') // for test
                .forEach(function(el) {
                    var info = {};
                    var cr = el.getBoundingClientRect();
                    var key;

                    // 1. getBoundingClientRect 位置信息
                    for (key in cr) {
                        info[key] = cr[key];
                    }

                    // 2. el 属性值为字符串和数值类型的属性
                    for (key in el) {
                        if (/string|number/.test(typeof el[key]) && el[key]) {
                            info[key] = el[key];
                        }
                    }

                    // 3. data-bh 或 data-bhclick 参数值
                    info.bh = attr(el, 'data-bh') || attr(el, 'data-bhclick');

                    elInfo.push(info);
                });

                return elInfo;
            }
        }
    };
    var URLS = MZTJ.url;
    var MSG = MZTJ.msg;
    var UTIL = MZTJ.util;

    // 判断来源域名是否在白名单内
    function inWhiteList(origin) {
        var ok = FALSEVAL;
        ['meizu.com', 'meizu.cn', 'flyme.cn'].forEach(function(whiteUrl) {
            if (origin.slice(origin.length - whiteUrl.length) === whiteUrl) {
                ok = TRUEVAL;
            }
        });
        return ok;
    }

    // postMessage 消息接收处理：
    // e.data.type 必须参数存在
    // e.data.type = 'bhel' 时收集符合规范的埋点标签信息
    function onMessage(e) {
        var data = e.data,
            source = e.source,
            origin = e.origin;

        if(!WIN.postMessage || source === WIN || !inWhiteList(origin) || !data.type) {
            console.log('fail')
            return FALSEVAL;
        }

        if (data.mo && !MSSG.initedMo) {
            MSG.mo(data.mo, source, origin);
        }

        MSG.s(data, source, origin);
    }

    /**
     * MeizuLogger
     * @type {Object}
     */
    MeizuLogger.prototype = {
        getType: function (type) {
            // var href = LOCATION.href;
            // var http = ('https:' === LOCATION.protocol ? 'https' : 'http') + '://tongji.meizu.com/flow/';
            var http = 'https://tongji.mlinkapp.com/flow/';
            var json = [{
                type: 1, //普通统计需求
                fn: 'getTongjiMC',//客户端方法名
                url: http + 'mc?' //ajax请求的url
            }, {
                type: 2, //统计script脚本报错
                fn: 'getTongjiJE',
                url: http + 'je?'
            }, {
                type: 3, //统计页面停留时长
                fn: 'getTongjiST',
                url: http + 'st?'
            }];

            return type ? json[type - 1] : json;
        },
        imgOnload: function(href, query, cb) {
            var image = new Image();
            image.onload = cb || function (e) {};
            image.src = this.makeUrl(href, query);
            return image;
        },
        // 发起请求
        request: function (action, type) {
            var reqCfg = this.getType(type);
            var href = reqCfg.url;
            var fn = reqCfg.fn;
            var query = this.getQueryJson(action, type);

            // 客户端内实现了相关接口，并且不是在浏览器里
            // 则调用客户端实现的上报方法
            if (!MZJI.isMzBrowser && MZPJI[fn]) {
                MZPJI[fn](JSON.stringify({
                    data: query
                }));
            } else {
                this.imgOnload(href, query);
            }
        },
        /**
         * 拼接Url字符串，用于兼容旧 API
         */
        makeUrl: URLS.make,
        //js报错信息收集
        jsError: function () {
            var self = this;
            WIN.onerror = function(msg, file, lines) {
                if (typeof msg !== "string") {
                    return;
                }

                var stack = [];
                var caller = arguments.callee.caller;
                var regGetFunName = /function (\w*\([^(]*\))/;
                var funCode, match;
                while (caller) {
                    funCode = caller.toString();
                    match = funCode.match(regGetFunName);
                    stack.push((match && match[1]) || funCode);
                    caller = caller.caller;
                }
                //上报日志
                var json = {
                    mg: msg,
                    fi: file || '',
                    li: lines,
                    stk: MZTJ.util.tof(stack.join(''), 50) //报错代码所在的函数片段，不一定都有
                }

                self.request(json, 2);
            }
        },
        // 扩展上报的字段
        extendData: function (json) {
            // 浏览器 WIN.MzJavascriptInterface 相关
            if (MZJI.getUMID) {
                json.umid = MZJI.getUMID();
            }
            if (MZJI.getDeviceModel) {
                json.mdevice = MZJI.getDeviceModel();//的各个设备
            }
            if (MZJI.getUMID) {
                json.flymeid = MZJI.getFlymeUid();
            }

            //时间
            var pt = WIN.performance && WIN.performance.timing;
            if (pt) {
                json.dnst = pt.domainLookupEnd - pt.domainLookupStart;//DNS查询耗时
                json.tcpt = pt.connectEnd - pt.connectStart;//TCP链接耗时
                json.rest = pt.responseEnd - pt.responseStart;//request请求耗时
                json.wst = pt.responseStart - pt.navigationStart;//白屏时间
                json.domt = pt.domComplete - pt.domInteractive;//解析dom树耗时
                json.domrt = pt.domContentLoadedEventEnd - pt.navigationStart;//domready时间
                json.plt = pt.loadEventStart - pt.navigationStart;//页面加载时间
                json.ldt = (pt.loadEventEnd || +new Date()) - pt.navigationStart;//onload时间
            }
            return json;
        },
        // 全局点击事件
        topGlobalClick: function () {
            var self = this;
            BODY.onclick = function (event) {
                var bhKey;
                // 注意：此处 window.event 不能改写为 WIN.event，兼容 IE 会报错
                event = event || window.event || {};
                var element = event.target || event.srcElement;

                while (element) {
                    bhKey = UTIL.attr(element, 'data-bh');
                    if (bhKey) {
                        break;
                    } else {
                        element = element.parentNode;
                    }
                }

                // dom 上定义的事件上报
                if (bhKey) {
                    self.request(bhKey, 1);
                }

                // 可手动关闭点击区域上报
                if (!USERCFG.offReportClickPos) {
                    self.clkEvHandler(event);
                }
            };
        },
        // 行为点击数据收集处理
        clkEvHandler: function (event) {
            var info = MZTJ.i;
            var pageX, pageY;
            var docEl = DOC.documentElement;

            // console.log(event.pageX, event.pageY, event);
            // 差异太大的信息丢掉逻辑
            // console.log('分辨率与屏幕大小差异', WIN.screen.width - WIN.innerWidth, WIN.innerWidth);
            if (MATH.abs(WIN.screen.width - WIN.innerWidth) > 300 && WIN.innerWidth < 768) {
                return;
            }

            if (info.ie) {
                pageX = event.clientX + MATH.max(docEl.scrollLeft, BODY.scrollLeft);
                pageY = event.clientY + MATH.max(docEl.scrollTop, BODY.scrollTop);
            } else {
                pageX = event.pageX;
                pageY = event.pageY;
            }

            if (!pageX && !pageY) {
                return;
            }


            // pageX 位置修正
            var pageWidth = MATH.max(BODY.offsetWidth, docEl.clientWidth || DOC.innerWidth);
            switch (info.align) {
                case 1: // 居中对齐
                    pageX -= MATH.ceil(pageWidth / 2);
                    break;
                case 2: // 右对齐
                    pageX -= pageWidth;
            }

            // console.log('修正后的位置信息：', pageX, pageY);

            var clickPosData = this.clkData || [];
            clickPosData.push([pageX, pageY]);

            if (clickPosData.length > 9) {
                // console.log(clickPosData);
                // 超过十次，发送一次请求 todo
                this.sendClkData(clickPosData);
                clickPosData = [];
            }

            this.clkData = clickPosData;
        },
        // 发送页面位置点击数据
        sendClkData: function (data) {
            data = data || this.clkData;
            if (!data || !data.length || !WIN.JSON) {
                return;
            }

            this.request({
                action: '__page_pos_click__',
                data: JSON.stringify(data),
                // wdp: WIN.innerWidth + 'x' + WIN.innerHeight
            }, 1);
        },
        //获取url问号后的参数，先拿到json格式的
        getQueryJson: function (action, type) {
            var data = {};
            if (action) {
                if (action.constructor === String) {
                    if (~action.indexOf('=')) {
                        data = URLS.qo(action);
                    } else {
                        data.action = action;
                    }
                } else if (action.constructor === Object) {
                    data = action;
                }
            }
            var connection = NAV.connection || NAV.mozConnection || NAV.webkitConnection || {};
            //var type_text = ['unknown', 'ethernet', 'wifi', '2g', '3g', '4g', 'none'];
            var screenWH = WIN.screen.width + 'x' + WIN.screen.height;
            var json = {
                ver: VERSION,       // version版本
                re: DOC.referrer || '',   //当前页面的来源
                rnd: parseInt(MATH.random() * 10000000),
                rc: URLS.qs("refcode"),
                ru: LOCATION.href,  //当前网页url
                dp: screenWH,       //分辨率
                nt: MZJI.getNetworkType ? MZJI.getNetworkType() : (connection.type || 'unknown') //网络连接类型
            };

            // 将 action 相关信息合进来
            var i;
            for (i in data) {
                if (!json[i]) {
                    json[i] = data[i];
                }
            }

            // 为 jsError 的信息上报
            if (type === 2) {
                action.ru = json.ru;
                json = action;
            }

            // 设置了 maxUrlLen，则对 re 和 ru 参数作截断
            var maxUrlLen = +USERCFG.maxUrlLen;
            if (maxUrlLen > 9) {
                var objItemSubstr = function(obj, key) {
                    if(obj[key] && obj[key].substr) {
                        obj[key] = obj[key].substr(0, maxUrlLen);
                    }
                }
                objItemSubstr(json, 'ru');
                objItemSubstr(json, 're');
                // objItemSubstr(json, 'fi');
            }

            json = this.extendData(json);
            return json;
        },
        // 获取页面停留时间
        getStayTime: function () {
            var self = this;
            var startTime = +new Date();
            var goodexit = FALSEVAL;

            function unbindunbeforunload() {
                goodexit = TRUEVAL;
                var endTime = +new Date();
                var st = endTime - startTime;
                self.request({
                    st: st / 1000
                }, 3);
                self.sendClkData();
                WIN.onbeforeunload = null;
            }

            function perforresult() {
                if (!goodexit) {
                    unbindunbeforunload();
                }
            }

            function bindunbeforunload() {
                goodexit = FALSEVAL;
                WIN.onbeforeunload = perforresult;
            }

            bindunbeforunload();
        },
        // 入口
        render: function () {
            // console.log('flow.js render', LOCATION.href);
            this.topGlobalClick();
            this.jsError();
            this.getStayTime();
            if (!USERCFG.unload) {
                this.request(null, 1);
            }
        }
    };
    /*
     * @example
     * MeizuBH("order_add") =等于= MeizuBH("action=order_add")
     * MeizuBH({action:order_add,id:xxx}) =等于= MeizuBH("action=order_add&id=xxxx")
     *
     * or
     * <div data-bh="action=order_add"></div>
     * <div data-bh="id=xxx"></div>
     * <div data-bh="order_add"></div>
     */
    var meizuLogger = WIN.meizuLogger = new MeizuLogger();
    WIN.MeizuBH = function (action) {
        return meizuLogger.request(action, 1);
    }

    var inited;
    var initMeizuBH = function () {
        inited = TRUEVAL;
        meizuLogger.render();
    }

    // 10s 后还没有初始化，触发它
    setTimeout(function() {
        if (!inited) {
            console.log('Force active initialization flow.js');
            initMeizuBH();
        }
    }, 10000);

    var addEvent = MZTJ.event.c;
    // UMD
    if (typeof define === 'function' && (define.amd || define.cmd)) {
        // AMD && CMD
        define(function () {
            meizuLogger.render();
            return WIN.MeizuBH;
        });
    } else if (typeof exports === 'object' && typeof module === 'object') {
        // CommonJS
        meizuLogger.render();
        module.exports = WIN.MeizuBH;
    } else {
        addEvent(WIN, 'load', initMeizuBH);
        addEvent(WIN, 'message', onMessage);
    }

})();
