







/* tslint:disable:no-console */
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import utils from "@/utils";

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "QrCode"
})
export default class QrCode extends Vue {
  private img: any = require("@/assets/images/code.png");
}
