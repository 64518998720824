import Vue from 'vue';
import Builder from '@/components/base/builder';
import Dialog from '@/components/base/dialog/dialog.vue';

const singleInstance: any[] = [];
interface IdiaslogOps {
  ctx?: any;
  replace?: boolean;   // 是否可以重复弹出
  position?: string;
  title?: string | any;   // 标题可以是文字和组件
  content?: string | any; // 内容可以是文字和组件
  transition?: string;    // 动画类型
  onCancel?: any; // 取消回调
  onConfirm?: any; // 确认回调
  cancelBtnTxt?: string;
  confirmBtnTxt?: string;
  data?: any;
}

class DialogBuilder extends Builder {
  public static cancel(): void {
    if (singleInstance.length) {
      const len = singleInstance.length
      // singleInstance[len - 1].$destroy();
      singleInstance.pop()
    }
  }
  public static tips(params: any = {}): void {
    return DialogBuilder.create(params, () => import(/* webpackChunkName: "tips" */ './tips.vue'));
  }
  public static create(params: any, Component: any): any {
    if (typeof window === 'undefined') {
      return;
    }
    let ops: IdiaslogOps = { replace: false };
    if (typeof params === 'string') {
      ops = { ...ops, content: params }
    } else if (params instanceof Object) {
      // 判断是否组件
      const title = typeof params.title === 'function' ? Vue.extend(params.title) : params.title;
      const content = typeof params.content === 'function' ? Vue.extend(params.content) : params.content;
      ops = { ...ops, ...params, title, content }
    } else {
      console.log('参数错误')
      return;
    }
    ops.onCancel = [DialogBuilder.cancel, ops.onCancel]
    // 重复生成
    if (ops.replace) {
      return DialogBuilder.createInstance(ops, Component)
    } else {
      // 不允许重复生成
      if (!singleInstance[0]) {
        return DialogBuilder.createInstance(ops, Component)
      }
      return singleInstance[0]
    }
  }
  public static createInstance(ops: any, Component: any): any {
    let instance: any;
    let tmpInstance: any;
    instance = new DialogBuilder(ops.ctx || this)
    tmpInstance = instance.createComp({ ...ops, container: Component })
    tmpInstance._bindingCtx = instance.ctx;
    tmpInstance._bindingIndex = singleInstance.length;
    singleInstance.push(tmpInstance);
    return tmpInstance
  }
  constructor(ctx: any) {
    super(ctx, Dialog)
  }
}
export default DialogBuilder