import Vue from 'vue';
import Builder from '@/components/base/builder';
import Loading from '@/components/base/loading/loading.vue';

// const Loading = Vue.extend(loading);
// let instance: any;
let singleInstance: any = null;

/* export default {
  open(options: any = {}) {
    if (!instance) {
      instance = new Loading({
        el: document.createElement('div')
      });
    }
    if (instance.visible) {
      return;
    }
    instance.text = typeof options === 'string' ? options : options.text || '';
    instance.spinnerType = options.spinnerType || 'snake';
    document.body.appendChild(instance.$el);

    Vue.nextTick(() => {
      instance.visible = true;
    });
  },

  close() {
    if (instance) {
      instance.visible = false;
    }
  }
}; */

class LoadingBuilder extends Builder {
  public static of(ctx: any) {
    if (singleInstance) {
      singleInstance._bindingCtx = ctx;
      return singleInstance
    }
    return new LoadingBuilder(ctx);
  }
  public static close(): void {
    if (singleInstance) {
      // singleInstance.$destroy();
      singleInstance.visible = false;
      singleInstance._bindingCtx = null;
      singleInstance = null;
    }
  }
  public static open(options: any = {}): void {
    if (typeof window === 'undefined') {
      return;
    }
    if (!singleInstance) {
      const instance = new LoadingBuilder(options.ctx || this)
      singleInstance = instance.createComp(options);
      singleInstance._bindingCtx = instance.ctx;
    }
    return singleInstance;
  }
  constructor(ctx: any) {
    super(ctx, Loading)
  }
}
export default LoadingBuilder