import Vue from "vue";
import axios from 'axios';
import qs from 'qs';
import md5 from 'md5'
import LRU from 'lru-cache'

const ERROR_MSG = '服务器错误，请稍候再试';
const baseURL = 'https://api-store.mlinkapp.com';
// 公共参数
const params: any = {
    os: 28,
    vc: 99900000,
    dataVcode: 99900000,
}
export const createRequest: any = function (withCredentials: boolean = true): any {
    return axios.create({
        baseURL,
        timeout: 10000, // 设置超时时间
        withCredentials, // 允许跨域 cookie
        maxContentLength: 200000,
    })
}

const microCache = new LRU({
    max: 100,
    maxAge: 5000 // 设置数据多久过期
})

const server: any = new createRequest();
server.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// require('@/mock/index.js')

const api: any = {
    request(method: string, url: string, data: any): any {
        // 判断是否有缓存,直接返回缓存结果
        const cacheKey = md5(url + JSON.stringify(data))
        const nCache = microCache.get(cacheKey)
        if ((typeof data.cache === 'undefined' || data.cache) && nCache) {
            return Promise.resolve(nCache)
        }
        return new Promise((resolve: any, reject: any) => {
            // 合并参数
            const datas = { ...params, ...data, timestamp: new Date().getTime()}
            server.request({
                method,
                url,
                params: method === 'get' ? datas : {},
                data: method !== 'get' ? qs.stringify(datas) : '',
            }).then((res: any) => {
                const value = res.data;
                if (typeof value.code === 'undefined' || Number(value.code) !== 200) {
                    return reject(value)
                }
                // 判断是否需要缓存 如果需要缓存缓存数据
                if ((typeof data.cache === 'undefined' || data.cache) && microCache) {
                    microCache.set(cacheKey, value)
                }
                return resolve(value)
            }).catch((error: any) => {
                const message = error.message || ERROR_MSG
                return reject(message)
            })
        })
    },
    get (url: string, data: any): any {
        return api.request('get', url, data)
    },
    post(url: string, data: any): any {
        return api.request('post', url, data)
    }
}

export default api
