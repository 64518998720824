/* tslint:disable */
const install = (Vue: any) => {
    const Bus = new Vue({
        methods: {
            emit (event: string) {
                const self: any = this;
                const args = [].slice.call(arguments, 1);
                self.$emit(event, ...args)
            },
            on (event: string, callback: any) {
                const self: any = this;
                self.$emit(event, callback)
            },
            off (event: string, callback: any) {
                const self: any = this;
                self.$off(event, callback)
            }
        },
    });
    Vue.prototype.$bus = Bus
}

export default install