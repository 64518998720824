import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/views/Index.vue';
import { routerMap } from '@/configs'

Vue.use(Router);

const routerMapKeys = Object.keys(routerMap);
export const createRouter = () => new Router({
  mode: 'history', // 一定要是history模式
  base: '/', // process.env.BASE_URL,
  routes: [
    // 首页
    /* {
      path: routerMap[routerMapKeys[0]].path,
      name: routerMapKeys[0],
      component: Index,
      meta: {
        pageTitle: '首页',
        keepAlive: true,
        scrollTop: 0,
      },
    }, */
    ...routerMapKeys.slice(0).map((it: any) => {
      const compName = it.substring(0, 1).toUpperCase() + it.substring(1);  // 首字母大写
      return {
        path: routerMap[it].path,
        name: it,
        component: () => import(
          /* webpackChunkName: "[request]" */
          `./views/${compName}.vue`),
        meta: {
          keepAlive: routerMap[it].keepAlive,
          scrollTop: '',
        }
      }
    })
  ],
});
