// polyfill
import 'intersection-observer';

const noop = function (): void {
    return;
}
export default class Exposure {
    // Observer为观察者的集合
    private Observer: any;
    private datas: any[] = [];
    private callBack: any;

    constructor(callBack: any) {
        this.callBack = callBack
        // 全局只会实例化一次Exposure类，init方法也只会执行一次
        this.init();
    }

    private init() {
        const self = this;
        // init只会执行一次，所以这两边界处理方法放这就行
        const CIntersectionObserver: any = typeof IntersectionObserver !== 'undefined' ? IntersectionObserver : noop;
        // 可以把节流的时间调大一点，默认是100ms
        CIntersectionObserver.prototype.THROTTLE_TIMEOUT = 300;
        this.Observer = new CIntersectionObserver((entries: any, observer: any): void => {
            entries.forEach((entry: any) => {
                // 这段逻辑，是每一个商品进入视窗时都会触发的
                if (entry.isIntersecting) {
                    // 我这里是直接把商品相关的数据直接放DOM上面了  比如 <div {...什么id  class style等属性} :data-dot="渲染商品流时自行加上自身属性" ></div>
                    // const ctm = entry.target.attributes['data-dot'].value;
                    if (typeof self.callBack === 'function') {
                        const key = entry.target.getAttribute("data-key");
                        for (const item of self.datas) {
                            if (item[key]) {
                                self.callBack(item[key])
                                break;
                            }
                        }
                    }
                    entry.target.setAttribute("exp", 1);
                    // 收集到该商品的数据后，取消对该商品DOM的观察
                    self.Observer.unobserve(entry.target);
                }
            })
        }, {
                root: null,
                rootMargin: "0px",
                threshold: 0.5 // 不一定非得全部露出来  这个阈值可以小一点点
            });

    }

    // 每个商品都会会通过全局唯一的Exposure的实例来执行该add方法,将自己添加进观察者中
    private add(entry: any) {
        // 生成key
        const data = entry.data;
        const key = (data.block_type || 'block') + '_' + (data.pos_hor || 0) + '_' + (data.pos_ver || 0);
        entry.el.setAttribute('data-key', key);
        this.datas.push({
            [key]: entry.data
        })
        if (this.Observer) {
            this.Observer.observe(entry.el)
        }
    }
}