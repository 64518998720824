import Vue from 'vue';
import Vuex from 'vuex';
import { state, mutations, actions } from './state';

Vue.use(Vuex);

export default function createStore() {
    return new Vuex.Store({
        state,
        mutations,
        actions,
    });
}

