import { createComponentProxy } from '@/utils';
import Vue from 'vue';
import store from '@/store';
// const _instancePool = {};
class Builder {
    public ctx: any;
    public comp: any;
    public ops: any;
    constructor(ctx: any, comp: any) {
        this.ctx = ctx;
        this.comp = comp;
    }
    public with(comp: any) {
        this.comp = comp;
        return this;
    }
    public createComp(opts?: any) {
        this.ops = opts;
        // const Proxy = createComponentProxy(this.comp, opts);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        const instance = new this.comp({
            name: `${this.comp.name || Date.now()}_Root`,
            el: anchor,
            propsData: opts,
            store,
        });
        return instance;
    }
}

export default Builder;