































import { Component, Prop, Vue } from "vue-property-decorator";
import ContainerBase from "@/components/base/dialog/containerBase.vue";

@Component({
  name: "Dialog",
  components: {ContainerBase},
})
export default class Dialog extends Vue {
  @Prop({ default: false }) private replace!: boolean;
  @Prop({ default: '50% 50%' }) private position!: string;
  @Prop({ default: 'fade' }) private transition!: string;
  @Prop({ default: null }) private container!: any; // 组件
  @Prop({ default: '标题' }) private title!: any;
  @Prop({ default: '我是内容' }) private content!: any;
  @Prop({ default: '取消' }) private cancelBtnTxt!: any;
  @Prop({ default: '确认' }) private confirmBtnTxt!: any;
  @Prop({ default: null }) private onCancel!: any;
  @Prop({ default: null }) private onConfirm!: any;
  @Prop({ default: null }) private data!: any;

  private open: boolean = true;
  private componentName: string = "";

  get boxStyle(): string {
    return ''
  }
  private beforeMount(): void {
    const self: any = this;
    self.$options.components.Container = self.container;
    self.componentName = 'Container';
  }
  private mounted(): void {
    const self: any = this;
  }
  private destroyed(): void {
    this.$el.remove();
  }
  private afterLeave(): void {
    this.$destroy();
  }
  private cancel (vm: any) {
    this.open = false;
    this.onCancel.map((fn: any) => {
      if (typeof fn === 'function') {
        fn(this);
      }
    })
  }
  private confirm (vm: any) {
    if (typeof this.onConfirm === 'function') {
      this.onConfirm()
    }
  }
}
