import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import { createRouter } from './router';
import createStore from './store';
import { sync } from 'vuex-router-sync';
// import './registerServiceWorker';
import Mint from '@/mint-ui/index';
import 'mint-ui/lib/style.css';
import utils from '@/utils';
import Bus from '@/components/base/bus';
import Motions from '@/components/base/motions';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(Mint);
Vue.use(Bus);
Vue.use(Motions);
Vue.prototype.utils = utils;

Vue.mixin({
  data: () => {
    return {
      isServer: process.env.VUE_ENV === "server", // 是服务端的状态
      noData: true,   // 是否获取到了数据
    }
  }
})

export function createApp() {
  // 创建 router 和 store 实例
  const router = createRouter()
  const store = createStore()

  // 同步路由状态(route state)到 store
  sync(store, router)

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });
  return { app, router, store };
}

/* const TARGET_DEV = process.env.WEBPACK_TARGET === "dev";
// 开发需要挂载
if (TARGET_DEV) {
  const { app } = createApp()
  app.$mount('#app')
} */
