import Vue from 'vue';
// import Mintui from 'mint-ui';
/* import {
    // Toast,
    MessageBox,
    // Indicator,
    // Cell,
    // Button,
    // Header,
    // Radio,
    // Loadmore,
} from 'mint-ui'; */
import Loading from '@/components/base/loading/index.ts'
import Totast from '@/components/base/toast/index.ts'
import Dialog from '@/components/base/dialog/index.ts'

const MintUI = {
    install() {
        // Vue.component(Cell.name, Cell);
        // Vue.component(Button.name, Button);
        // Vue.component(Header.name, Header);
        // Vue.component(Radio.name, Radio);
        // Vue.component(Loadmore.name, Loadmore);
        Vue.prototype.$toast = Totast;
        Vue.prototype.$dialog = Dialog;
        // Vue.prototype.$alert = MessageBox.alert;
        // Vue.prototype.$confirm = MessageBox.confirm;
        Vue.prototype.$loading = Loading;
    },
};

export default MintUI;
